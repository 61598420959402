
    import {defineComponent, ref, onMounted, reactive, getCurrentInstance} from 'vue';
    import {message} from "ant-design-vue";
    import {
        getAccept,
        getProcess,
        getFinish,
        getVerify,
        getDetail,
        getUser,
        setAcceptPeople,
        getGd,
        setReturnAcceptPeople,
        getFini,
        getRole1,
        setBack,
        returnVerifyGd,
    } from '@/network/seller';
    import moment from 'moment';
    import baseUrl from "@/util/api"
    /*interface DataItem {
        logo:string;
        need1: string;
        need2: string;
        need3: string;
        need4: string;
        need5: string;
        file: string[];
        department: any;
        type:number;
        name: string;
        link1: string;
        link2: string;
        link3: string;
        link4: string;
        link5: string;
    }*/
    export default defineComponent({
        name: "Dindex",
        setup() {
            const internalInstance: any = getCurrentInstance();
            let uid = window.localStorage.getItem('uid');
            const acceptRes = ref([]);
            const processRes = ref([]);
            const finishRes = ref([]);
            const verifyRes = ref([]);
            const activeKey = ref('1');
            const accept = () => {
                getAccept().then((response) => {
                    if (response.status == 'done') {
                        acceptRes.value = response.res;
                        //console.log(data);
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
            };
            const process = () => {
                getProcess({'uid': uid}).then((response) => {
                    if (response.status == 'done') {
                        processRes.value = response.res;
                        //console.log(data);
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
            };
            const finish = () => {
                getFinish({'uid': uid}).then((response) => {
                    if (response.status == 'done') {
                        finishRes.value = response.res;
                        //console.log(data);
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
            };
            const verify = () => {
                getVerify().then((response) => {
                    if (response.status == 'done') {
                        verifyRes.value = response.res;
                        //console.log(data);
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
            };
            const getTime = (time) => {
                return moment(time * 1000).format("MM-DD HH:mm");
            };
            const visible = ref<boolean>(false);
            const visible1 = ref<boolean>(false);
            const visible2 = ref<boolean>(false);
            const visible3 = ref<boolean>(false);
            const is_type = ref<number>(0);
            const is_accept = ref<boolean>(false);
            const detail = ref<any>('');
            const detail1 = ref<any>('');
            const file = ref<string[]>([]);
            const gdid = ref<number>(0);
            const ver = async(id) => {
                visible.value = true;
                file.value = [];
                detail.value = '';
                await getDetail({'id': id}).then((response) => {
                    if (response.status == 'done') {
                        gdid.value = id;
                        user();
                        detail.value = response.data;
                        if (detail.value.file) {
                            file.value = detail.value.file.split(',');
                            console.log(file.value);
                        }
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            };
            const getExt = (filename) => {
                var filePath = filename;
                var index = filePath.lastIndexOf(".");
                var ext = filePath.substr(index + 1);
                var acceptFileTypes = /^(docx|doc|rar|zip)$/i;
                if (acceptFileTypes.test(ext)) {
                    return true;
                    //该文件是 pdf|docx|doc其中一种
                } else {
                    return false;
                    //该文件不是 pdf|docx|doc其中一种
                }
            }
            const options = ref([])
            const user = () => {
                getUser().then((response) => {
                    if (response.status == 'done') {
                        options.value = response.res;
                    } else {
                        message.error(`${response.msg} `);
                    }
                });
            }
            const acceptPeople = ref<any>(undefined);
            const acceptPeople1 = ref<any>(undefined);
            const handleChange = (value: string) => {
                //console.log(`selected ${value}`);
                acceptPeople.value = value;
            };
            const handleChange1 = (value: string) => {
                console.log(`selected ${value}`);
                acceptPeople1.value = value;
            };
            const handleOk = (e: MouseEvent) => {
                if(!acceptPeople.value){
                    message.error('请选择接单人!');
                    return
                }
                setAccept();
                visible.value = false;
            };
            const handleOk1 = (e: MouseEvent) => {
                visible1.value = false;
                getGd({'uid': uid, 'id': gdid.value}).then((response) => {
                    if (response.status == 'done') {
                        accept();
                        message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            };
            const handleOk2 = (e: MouseEvent) => {
                visible2.value = false;
                if (acceptPeople1.value) {
                    setReturnAccept();
                } else {
                    getFini({'id': gdid.value}).then((response) => {
                        if (response.status == 'done') {
                            process();
                            message.success(`${response.msg} `);
                        } else {
                            message.error(`${response.msg} `);
                        }
                    })
                }
            };
            const handleCancel = () => {
                acceptPeople.value = undefined;
                visible.value = false;
            };
            const handleCancel1 = () => {
                is_type.value = 0;
                is_accept.value = false;
                visible1.value = false;
            };
            const handleCancel2 = () => {
                is_type.value = 0;
                acceptPeople1.value = undefined;
                visible2.value = false;
            };
            const handleCancel3 = () => {
                is_type.value = 0;
                visible3.value = false;
            };
            const setAccept = () => {
                setAcceptPeople({'fixer': acceptPeople.value, 'id': gdid.value}).then((response) => {
                    if (response.status == 'done') {
                        verify();
                        internalInstance?.proxy.$socket.sendObj({
                            type: 'text',
                            msg: "你有新的任务！",
                            mode: 4,
                            //creator:window.localStorage.getItem('uname'),
                            fixer: acceptPeople.value,
                        });
                        message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                    acceptPeople.value = undefined;
                })
            }
            const setReturnAccept = () => {
                setReturnAcceptPeople({'fixer': acceptPeople1.value, 'id': gdid.value}).then((response) => {
                    if (response.status == 'done') {
                        internalInstance?.proxy.$socket.sendObj({
                            type: 'text',
                            msg: "你有新的任务！",
                            mode: 4,
                            //creator:window.localStorage.getItem('uname'),
                            fixer: acceptPeople1.value,
                        });
                        process();
                        message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                    acceptPeople1.value = undefined;
                })
            }
            //const showid = ref<number>(0);
            //const showtype = ref<number>(0);
            const get = async (id, type, fixer) => {
                visible1.value = true;
                file.value = [];
                detail1.value = '';
                prole();
                await getDetail({'id': id}).then((response) => {
                    if (response.status == 'done') {
                        //showid.value = id;
                        //showtype.value = type;
                        if (type == 1 && (role.value == 10 || role.value == 8)) {
                            //console.log(111111);
                            is_type.value = 1;
                            if (uid == fixer) {
                                is_accept.value = true;
                            } else {
                                is_accept.value = false;
                            }
                        } else if (type == 2) {
                            is_type.value = 2;
                            is_accept.value = true;
                        } else if (type == 0 && (role.value == 10 || role.value == 11)) {
                            is_type.value = 0;
                            is_accept.value = true;
                        }
                        gdid.value = id;
                        detail1.value = response.data;
                        if (detail1.value.file) {
                            file.value = detail1.value.file.split(',');
                            //console.log(file.value);
                        }
                        console.log(is_type.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const processing = async(id, type) => {
                visible2.value = true;
                file.value = [];
                detail1.value = '';
                prole();
                await getDetail({'id': id}).then((response) => {
                    if (response.status == 'done') {
                        user();
                        gdid.value = id;
                        detail1.value = response.data;
                        if (type == 1 && (role.value == 10 || role.value == 8)) {
                            is_type.value = 1;
                        } else if (type == 2) {
                            is_type.value = 2;
                            is_accept.value = true;
                        } else if (type == 0 && (role.value == 10 || role.value == 11)) {
                            is_type.value = 0;
                            is_accept.value = true;
                        }
                        if (detail1.value.file) {
                            file.value = detail1.value.file.split(',');
                            //console.log(file.value);
                        }
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const fini = async (id, type) => {
                visible3.value = true;
                file.value = [];
                detail1.value = '';
                prole();
                await getDetail({'id': id}).then((response) => {
                    if (response.status == 'done') {
                        gdid.value = id;
                        detail1.value = response.data;
                        if (type == 1 && (role.value == 10 || role.value == 8)) {
                            console.log(2222);
                            is_type.value = 1;
                        } else if (type == 2) {
                            is_type.value = 2;
                            is_accept.value = true;
                        } else if (type == 0 && (role.value == 10 || role.value == 11)) {
                            is_type.value = 0;
                            is_accept.value = true;
                        }
                        if (detail1.value.file) {
                            file.value = detail1.value.file.split(',');
                            //console.log(file.value);
                        }
                        console.log(is_type.value);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const role = ref(0);
            const prole = async () => {
                await getRole1({'uid': uid}).then((response) => {
                    if (response.status == 'done') {
                        role.value = response.role;
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const handleBack = (creator,id)=>{
                setBack({'id': id}).then((response)=>{
                    if (response.status == 'done') {
                        internalInstance?.proxy.$socket.sendObj({
                            type: 'text',
                            msg: "你的任务被驳回！",
                            mode: 5,
                            //creator:window.localStorage.getItem('uname'),
                            creator: creator,
                        });
                        verify();
                        visible.value = false;
                        message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const returnVerify = ()=>{
                returnVerifyGd({'id': gdid.value}).then((response)=>{
                    if (response.status == 'done') {
                        visible1.value = false;
                        accept();
                        message.success(`${response.msg} `);
                    } else {
                        message.error(`${response.msg} `);
                    }
                })
            }
            const onChange = () => {
                verify();
                accept();
                process();
                finish();
            }
            onMounted(() => {
                accept();
                process();
                finish();
                verify();
                prole();
            })
            return {
                activeKey,
                acceptRes,
                processRes,
                finishRes,
                verifyRes,
                getTime,
                ver,
                visible,
                visible1,
                visible2,
                visible3,
                detail,
                detail1,
                file,
                getExt,
                baseUrl: baseUrl.apiUrl,
                handleChange,
                handleChange1,
                options,
                handleOk,
                handleOk1,
                handleOk2,
                handleCancel,
                handleCancel1,
                handleCancel2,
                handleCancel3,
                acceptPeople,
                acceptPeople1,
                onChange,
                get,
                is_accept,
                is_type,
                processing,
                fini,
                handleBack,
                uid,
                role,
                returnVerify,
            };
        },
    });

import http from './http'

export function submit(data) {
    return http.post('/index/seller/submit', data)
}

export function getAccept(data) {
    return http.post('/index/seller/getAccept', data)
}

export function getProcess(data) {
    return http.post('/index/seller/getProcess', data)
}

export function getFinish(data) {
    return http.post('/index/seller/getFinish', data)
}

export function getVerify(data) {
    return http.post('/index/seller/getVerify', data)
}

export function getDetail(data) {
    return http.post('/index/seller/getDetail', data)
}

export function getUser(data) {
    return http.post('/index/seller/getUser', data)
}

export function setAcceptPeople(data) {
    return http.post('/index/seller/setAcceptPeople', data)
}

export function getList(data) {
    return http.post('/index/seller/getList', data)
}
export function getOperatorList(data) {
    return http.post('/index/seller/getOperatorList', data)
}

export function getGd(data) {
    return http.post('/index/seller/getGd', data)
}

export function close(data) {
    return http.post('/index/seller/close', data)
}
export function close1(data) {
    return http.post('/index/seller/close1', data)
}

export function setReturnAcceptPeople(data) {
    return http.post('/index/seller/setReturnAcceptPeople', data)
}

export function getFini(data) {
    return http.post('/index/seller/getFini', data)
}
export function getAnalyze(data) {
    return http.post('/index/seller/getAnalyze', data)
}
export function getRole1(data) {
    return http.post('/index/seller/getRole1', data)
}
export function submitOperator(data) {
    return http.post('/index/seller/submitOperator', data)
}
export function getOperatorDetail(data) {
    return http.post('/index/seller/getOperatorDetail', data)
}
export function setBack(data) {
    return http.post('/index/seller/setBack', data)
}
export function returnVerifyGd(data) {
    return http.post('/index/seller/returnVerifyGd', data)
}

